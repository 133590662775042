import React from "react";
import Button from "../input/Button";
import styles from "./Login.module.scss";
import { withOAuth } from 'aws-amplify-react';
import LoginHeader from "./LoginHeader";

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  render() {
    const { newOrgGoLiveTimestamp } = this.props;
    // The Cognito client ID (configured during app initialisation) determins which authentication methids are available
    return (
      <React.Fragment>
        <LoginHeader newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} isAdmin={true} />
        {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}
        <div className={styles.root}>
          <Button onClick={this.props.OAuthSignIn} block="true" style={{ margin: 0 }}>
            Sign in with Azure AD
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

AdminLogin.propTypes = {};

export default withOAuth(AdminLogin);