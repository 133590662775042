import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import authAPI from "../../api/authAPI";
import Button from "../input/Button";
import { TextField } from "formik-mui"

import styles from "./Login.module.scss";
import LoginHeader from "./LoginHeader";

const validationSchema = object().shape({
  password: string().required("Pakollinen kenttä")
});

const onCancel = () => {
  window.history.back();
};

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isSubmitting: false
    };
  }
  onSubmit = ({ password }, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ error: null });

    authAPI
      .setNewPassword(password)
      .then(() => {
        this.props.onAuth();
      })
      .catch(error => {
        this.setState({ error });
        setSubmitting(false);
      });
  };

  render() {
    const { newOrgGoLiveTimestamp } = this.props;
    return (
      <React.Fragment>
        <LoginHeader newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} />
        {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}
        <Formik
          initialValues={{"password": ""}}
          validationSchema={validationSchema.password}
          onSubmit={this.onSubmit}
        >
          {({ isValid }) => (
            <Form className={styles.root}>
              <Field
                type="password"
                name="password"
                placeholder="Uusi salasana"
                autoFocus
                component={TextField}
              />
              <Button type="submit" disabled={!isValid} block="true">
                Vaihda salasana
              </Button>
              <Button onClick={onCancel} block="true" cancel="cancel">
                Peruuta
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

UpdatePassword.propTypes = {
  onAuth: PropTypes.func.isRequired
};

export default UpdatePassword;
