import React from "react";
import PropTypes from "prop-types";

import Query from "../../apollo/extensions/CachedQuery"

import uniq from "lodash/uniq";

import * as Sentry from '@sentry/browser';

import getUniqueProps from "../../utils/getUniqueProps";
import { getOrderQuery, getOrderProps } from "../../api/graphql/getOrder";

import fetchTransactionInputs from "../../utils/fetchTransactionInputs";
import updateTransactionInput from "../../utils/updateTransactionInput";
import updateDestinationOrderCustomer from "../../utils/updateDestinationOrderCustomer";


import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import DestinationOrder from "../../components/routes/destination/DestinationOrder";
import { getWaybillSignatureWrapperFunction, isWaybillSigned } from "../../components/routes/utils/waybills";

const DestinationOrders = ({ destination, currentVehicle, companyName }) => {
  let transactions = destination.transactions;

  // Sort according to drive order
  transactions = transactions.sort((a, b) =>
    a.order - b.order
  );

  const transactionIds = getUniqueProps(transactions, "transactionId");
  const orderNums = getUniqueProps(transactions, "orderNum");
  const inferActualAmount = (order) => {
    let totalWeight = 0;
    order.rows.forEach((row) => {
      if (row.transactions && row.transactions.length > 0) {
        const transaction = row.transactions[0];
        const actualWeightKg = (transaction.actualAmount / row.orderedAmount) * row.weight;
        totalWeight += actualWeightKg;
      }
    });

    return totalWeight / 1000;
  };

  return orderNums.map(orderNum => (
    <Query
      key={orderNum}
      query={getOrderQuery}
      variables={{ orderId: `${orderNum}` }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, cached, error, data, client }) => {
        if (loading && !cached) return <Loading />;
        if(error) {
          Sentry.captureEvent({
            message: "Query error - DestinationOrders",
            extra: {
              'error': error,
              'data': data,
              'variableOrderId': orderNum
            },
          });
        }
        if (error) return <Error error={error} />;

        let loadCompleted = (destination.atd && destination.type === "load") ?? false;
        let unloadCompleted = (destination.atd && destination.type === "unload") ?? false;

        const order = getOrderProps(data);

        if (!order || !order.rows) return null;
        const routeId = destination.routeId;
        const waybillNumber = order.rows?.[0]?.transactions?.[0]?.waybillNum?? "";

        const updateOrderInfo = (waybillSigned = false) => {
          updateDestinationOrderCustomer(destination.id, {
            orderNum,
            customerNum: order.customerNum,
            date: order.rows?.[0]?.transactions?.[0]?.actualPickupStartTime?.split(" ")[0] ?? "",
            waybillNumber,
            customerEmail: order.customerData?.email ?? "",
            pickupEmail: order.rows?.[0]?.transactions?.[0]?.pickupEmail ?? "",
            unloadEmail: order.rows?.[0]?.transactions?.[0]?.unloadEmail ?? "",
            licenseNum: currentVehicle?.licenseNum ?? "",
            actualAmount: `${inferActualAmount(order)} tn`,
            pickupCity: order.rows?.[0]?.transactions?.[0]?.pickupCity ?? "",
            unloadCity: order.rows?.[0]?.transactions?.[0]?.unloadCity ?? "",
            waybillSigned
          });
        };

        if (waybillNumber) {
          getWaybillSignatureWrapperFunction(client, routeId, orderNum, waybillNumber)
          .then(signatures => {
            updateOrderInfo(isWaybillSigned(signatures, destination.type));
            }).catch(error => {
            console.log('Error fetching waybill signature', error);
            updateOrderInfo();
          });
        } else {
          updateOrderInfo();
        }

        const commodities = [];
        const unloadLocations = [];

        // FTL-184
        // Fetch this initially to try and avoid excessive local storage writes
        let transactionLoadInputs = fetchTransactionInputs();
        // Sync SISU data to local storage if it is missing and order data from SISU
        // has completed pickup (actualPickupEndTime is defined) and the waybill
        order.rows.forEach(row => {
          row.transactions.forEach(transaction => {
            // transaction.waybillNumber may not be sent out yet. It exists only in local storage when first set by user.
            const transactionId = destination.type === 'load' ? transaction.pickupTransactionId : transaction.unloadTransactionId
            const waybillNumber = transaction.waybillNum ? transactionLoadInputs[transactionId]?.waybill : undefined;
            if (waybillNumber) {
              getWaybillSignatureWrapperFunction(client, routeId, orderNum, waybillNumber)
              .then(signatures => {
                // We can set signed flag when waybillNumber is known
                updateTransactionInput(transactionId, { "signed": isWaybillSigned(signatures, destination.type) });
              }).catch(error => {
                console.log('Query error', error);
                return {};
              });
            }

            // question, was this intentionally only pickupTransaction, and not also unload.
            if (transaction.pickupTransactionId &&
                !transactionLoadInputs[transaction.pickupTransactionId] &&
                transaction.actualPickupEndTime
                && transaction.waybillNum
                && transaction.actualAmount) {

              updateTransactionInput(transaction.pickupTransactionId, {
                "waybill":transaction.waybillNum,
                "amountLoaded":transaction.actualAmount,
                "weightNoteNumberLoading":transaction.weightNoteNumberLoading,
                "weightNoteNumberUnloading":transaction.weightNoteNumberUnloading,
              })
            }
          });
        })
        // Refetch updated set of transactions
        transactionLoadInputs = fetchTransactionInputs();

        order.rows.forEach(row =>
          row.transactions.forEach(transaction => {
            // is this transaction a match for the destination to be shown?

            if (
              transactionIds.includes(transaction.pickupTransactionId) ||
              transactionIds.includes(transaction.unloadTransactionId)
            ) {
              const {
                orderedAmount,
                actualAmount,
                orderedUnit,
                actualUnit
              } = transaction;

              // yep, add the commodities and unloadlocations to the list to be shown for this order
              commodities.push({
                name: row.commodityName,
                amount:
                  !!actualAmount || actualAmount === 0
                    ? actualAmount
                    : orderedAmount,
                unit:
                  !!actualAmount || actualAmount === 0
                    ? actualUnit
                    : orderedUnit
              });

              unloadLocations.push(
                `${transaction.unloadLocation}, ${transaction.unloadCity}`
              );

              // load can not be completed if all waybill numbers and amounts are input are not given yet
              // Both loading & unloading use the same entry in local storage, which is supllied by the 'transactionLoadInputs' prop
              // The initial values of loadCompleted & unloadCompleted are set based on the state of the overall order
              if (destination.type === "load" && !loadCompleted) {
                const transactionLoadInput =
                  transactionLoadInputs[transaction.pickupTransactionId] || {};
                loadCompleted =
                  !!transactionLoadInput.waybill &&
                  !isNaN(transactionLoadInput.amountLoaded) &&
                  !!transactionLoadInput.signed;
              }

              if (destination.type === "unload" && !unloadCompleted) {
                const transactionUnloadInput =
                  transactionLoadInputs[transaction.unloadTransactionId] || {};
                unloadCompleted =
                  !!transactionUnloadInput.waybill &&
                  !isNaN(transactionUnloadInput.amountLoaded) &&
                  !!transactionUnloadInput.signed;
              }

            }
          })
        );

        return (
          <DestinationOrder
            orderNum={orderNum}
            customerOrderNum={order.customerOrderNum}
            commodities={uniq(commodities)}
            unloadLocations={uniq(unloadLocations)}
            additionalInfo={order.additionalInfo}
            loadCompleted={loadCompleted}
            unloadCompleted={unloadCompleted}
            companyName={companyName}
          />
        );
      }}
    </Query>
  ));
};

DestinationOrders.propTypes = {
  destination: PropTypes.shape({
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        transactionId: PropTypes.string,
        orderNum: PropTypes.string
      })
    ).isRequired,
    ata: PropTypes.string,
    atd: PropTypes.string,
    type: PropTypes.oneOf(["load", "unload"])
  }).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default DestinationOrders;
