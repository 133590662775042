import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { string, object } from "yup";
import authAPI from "../../api/authAPI";
import { TextField } from "formik-mui";

import DigitInput from "../input/DigitInput";
import Button from "../input/Button";

import styles from "./Login.module.scss";
import LoginHeader from "./LoginHeader";

const STATE_PROMPT_NAME = "state_prompt_name";
const STATE_PROMPT_VERIFICATION = "state_prompt_verification";
const STATE_SUCCESS = "state_success";

const initialValues = {
  username: {
    username: ""
  },
  verification: {
    verificationCode: "",
    newPassword: ""
  }
};

const validationSchema = {
  username: object().shape({
    username: string().required("Pakollinen kenttä")
  }),
  verification: object().shape({
    verificationCode: string()
      .required("Pakollinen kenttä")
      .min(6, "Vähintään kuusi numeroa"),
    newPassword: string().required("Pakollinen kenttä")
  })
};

const onCancel = () => {
  window.history.back();
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isSubmitting: false,
      currentState: STATE_PROMPT_NAME
    };
  }

  // Submit the username to send the verification code to
  onSubmitUsername = ({ username }, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ error: null, isSubmitting: true });

    // Send the user a verification code via email
    authAPI
      .requestPasswordReset(username)
      .then(() => {
        // username is needed when new password is set
        this.setState({
          currentState: STATE_PROMPT_VERIFICATION,
          isSubmitting: false,
          username
        });
      })
      .catch(error => {
        this.setState({ error });
        setSubmitting(false);
      });
  };

  // Submit the verification code and the new password
  onSubmitVerification = ({ verificationCode, newPassword }, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ error: null, isSubmitting: true });

    const { username } = this.state;

    // Set the new password
    authAPI
      .verifyPasswordReset(username, verificationCode, newPassword)
      .then(() => {
        // password was reset succesfully, auto-login with new credentials
        authAPI
          .signIn(username, newPassword)
          .then(() => {
            this.setState({
              currentState: STATE_SUCCESS,
              isSubmitting: false
            });
            setSubmitting(false);
          })
          .catch(error => {
            setSubmitting(false);
            this.setState({ error });
          });
      })
      .catch(error => {
        setSubmitting(false);
        this.setState({ error });
      });
  };

  render() {
    const { isSubmitting } = this.state;
    const { newOrgGoLiveTimestamp } = this.props;
    return (
      <React.Fragment>
        <LoginHeader newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} />
        {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}

        {this.state.currentState === STATE_PROMPT_NAME &&
          this.renderUsernameForm({ isSubmitting })}

        {this.state.currentState === STATE_PROMPT_VERIFICATION &&
          this.renderVerificationForm({ isSubmitting })}

        {this.state.currentState === STATE_SUCCESS && (
          <div style={{textAlign: "center"}}>
            <p>
              Salasana päivitetty
            </p>
            <Button onClick={this.props.onAuth}>Kirjaudu</Button>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderUsernameForm = () => {
    return (
      <Formik
        initialValues={initialValues.username}
        validationSchema={validationSchema.username}
        onSubmit={this.onSubmitUsername}
      >
        {({ isValid }) => (
          <Form className={styles.root}>
            <Field
              name="username" 
              placeholder="Käyttäjätunnus"
              autoFocus
              component={TextField}
              className={`${styles.field} ${styles.username}`}
            />
            <Button type="submit" disabled={!isValid} block="true">
              Vaihda salasana
            </Button>
            <Button onClick={onCancel} block="true" cancel="cancel">
              Peruuta
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  renderVerificationForm = () => {
    return (
      <Formik
        initialValues={initialValues.verification}
        validationSchema={validationSchema.verification}
        onSubmit={this.onSubmitVerification}
      >
        {({ isValid }) => (
          <Form className={`${styles.root} ${styles.signup}`}>
            <React.Fragment>
              <label>Pin-koodi</label>
              <Field
                name="verificationCode"
                render={({ field }) => <DigitInput {...field} />}
                autoFocus
              />
              <ErrorMessage name="verificationCode" component="span" />
              <br />
            </React.Fragment>
            <Field
              type="password"
              name="newPassword"
              placeholder="Uusi salasana"
              component={TextField}
              className={`${styles.field} ${styles.password}`}
            />
            <Button type="submit" disabled={!isValid} block="true">
              Päivitä salasana
            </Button>
            <Button onClick={onCancel} block="true" cancel="cancel">
              Peruuta
            </Button>
          </Form>
        )}
      </Formik>
    );
  };
}
ForgotPassword.propTypes = {
  onAuth: PropTypes.func.isRequired
};

export default ForgotPassword;
