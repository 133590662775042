import React from "react";
import styles from "./Login.module.scss";

const LoginHeader = ({ newOrgGoLiveTimestamp, isAdmin = false }) => {
  const now = new Date().toISOString();
  const loginHeaderClassName = newOrgGoLiveTimestamp && newOrgGoLiveTimestamp < now ? styles.simpleHeader : styles.loginHeader;

  return (
    <header className={loginHeaderClassName}>
      Rahtari{isAdmin ? " for VR Admins" : ""}
    </header>
  );
};

export default LoginHeader;