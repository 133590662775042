import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { Link } from "@reach/router";
import { string, object } from "yup";
import { TextField } from 'formik-mui';

import authAPI from "../../api/authAPI";
import Button from "../input/Button";

import styles from "./Login.module.scss";
import LoginHeader from "./LoginHeader";

const initialValues = {
  username: "",
  password: ""
};

const validationSchema = object().shape({
  username: string().required("Pakollinen kenttä"),
  password: string().required("Pakollinen kenttä")
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  onSubmit = ({ username, password }, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ error: null });

    authAPI
      .signIn(username, password)
      .then(() => {
        this.props.onAuth();
      })
      .catch(error => {
        setSubmitting(false);
        this.setState({
          error
        });
      });
  };

  render() {
    const { newOrgGoLiveTimestamp } = this.props;

    return (
      <React.Fragment>
        <LoginHeader newOrgGoLiveTimestamp={newOrgGoLiveTimestamp} />
        {this.state.error && <div className={styles.error}>{this.state.error.message}</div>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >
          {({ isValid }) => (
            <Form className={`${styles.root} ${styles.login}`}>
              <Field
                name="username"
                placeholder="Käyttäjätunnus"
                autoFocus
                component={TextField}
                className={`${styles.field} ${styles.username}`}
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
              <Field
                type="password"
                name="password"
                placeholder="Salasana"
                component={TextField}
                className={`${styles.field} ${styles.password}`}
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
              <Button type="submit" disabled={!isValid} block="true">
                Kirjaudu sisään
              </Button>
            </Form>
          )}
        </Formik>
        <Link to="/login/forgot" className={styles.extraLink}>Oletko unohtanut salasanan?</Link>
        <a href="https://www.vrtranspoint.fi/fi/vr-transpoint/tietosuoja/" target="_blank" rel="noopener noreferrer" className={styles.extraLink}>Tietosuoja­seloste</a>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  onAuth: PropTypes.func.isRequired
};

export default Login;
