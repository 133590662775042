import React from "react";
import PropTypes from "prop-types";

import getRestString from "../../../utils/getRestString";

import Panel from "../../layout/Panel";
import UnstyledLink from "../../layout/UnstyledLink";
import AdditionalInfo from "../AdditionalInfo";

import styles from "./DestinationOrder.module.scss";
import ArrowCircle from "../../layout/ArrowCircle";

const DestinationOrder = ({
  customerOrderNum,
  orderNum,
  commodities,
  unloadLocations,
  additionalInfo,
  loadCompleted,
  unloadCompleted,
  companyName,
}) => {
  const totalFirstCommodity = commodities.reduce((sum, commodity) => {
    if (commodity.name === commodities[0].name) {
      return sum + commodity.amount;
    } else {
      return sum;
    }
  }, 0);

  const uniqueCommodities = [
    ...new Set(commodities.map(commodity => commodity.name))
  ];

  return (
    <Panel className={styles.root}>
      <UnstyledLink to={`${orderNum}`}>
        {loadCompleted && <span className={styles.note}>LASTATTU</span>}
        {unloadCompleted && <span className={styles.note}>PURETTU</span>}
        <div className={styles.transactionHeader}>
          <div>
            {customerOrderNum && (
              <div className={styles.customerOrderNum}>
                <span className={styles.title}>Asiakkaan tilausnro</span>
                <span title={customerOrderNum}>{customerOrderNum}</span>
              </div>
            )}
            <div className={styles.transactionOrderNum}>
              <span className={styles.title}>{companyName} tilausnro</span>
              <span>{orderNum}</span>
            </div>
          </div>
          <div>
            <div className={styles.transactionRecipient}>
              <span className={styles.title}>Vastaanottaja</span>
              <span>
                {unloadLocations[0]}
                {getRestString(unloadLocations)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.transaction}>
          {commodities[0] ? commodities[0].name : ""},{" "}
          {totalFirstCommodity.toLocaleString("fi-FI")}{" "}
          {commodities[0] ? commodities[0].unit : ""}
          {getRestString(uniqueCommodities)}
        </div>
        {additionalInfo.length > 0 && (
          <AdditionalInfo>{additionalInfo}</AdditionalInfo>
        )}
        <ArrowCircle
          className={loadCompleted || unloadCompleted ? styles.completed : null}
        />
      </UnstyledLink>
    </Panel>
  );
};

DestinationOrder.propTypes = {
  customerOrderNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orderNum: PropTypes.string.isRequired,
  commodities: PropTypes.arrayOf(PropTypes.object).isRequired,
  unloadLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  additionalInfo: PropTypes.array,
  loadCompleted: PropTypes.bool,
  unloadCompleted: PropTypes.bool
};

export default DestinationOrder;
